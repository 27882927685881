import React, { useMemo } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  TextField,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Discount } from '../../core/discount/discount.service';
import { ProductAutocomplete } from '../autocomplete/product-autocomplete.container';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import {
  languages,
  LanguageSelector,
} from '../language-selector/language-selector.component';
import {
  emptyMultipleLanguageString,
  LanguageCode,
} from '../../core/global/language.constant';
import { clamp } from '../../core/global/mathExtended.contant';
import { Flag } from '../flag/flag.component';
import toast from 'react-hot-toast';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useDiscount } from '../../core/hooks/useDiscount';

const useStyles = makeStyles({
  required: {
    borderColor: '#f00',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
});

export const DiscountElement: React.FC = () => {
  const defaultDiscount: Discount = useMemo(
    () => ({
      id: '',
      code: '',
      timeCreated: new Date(),
      description: emptyMultipleLanguageString(),
      totalUses: 0,
      minimumAmountRequired: 0,
      maximumApplyableDiscount: 0,
      percentageDiscount: 0,
      flatDiscount: 0,
      freeProducts: [],
      validProducts: [],
      requiredProducts: [],
      timeExpired: null,
    }),
    [],
  );

  const { fetchId, update, create, remove, loading } = useDiscount();

  const [discount, setDiscount] = useState<Discount | undefined>();
  const [mode, setMode] = useState<'edit' | 'add'>('add');
  const [language, setLanguage] = useState<LanguageCode>(LanguageCode.en);
  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);
  const [timeExpired, setTimeExpired] = useState<Date | null | undefined>(null);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const classes = useStyles();

  useEffect(() => {
    if (id === 'add') {
      setDiscount(defaultDiscount);
      return;
    }
    const tmpDiscount = fetchId(id);
    setDiscount(tmpDiscount);
    setMode('edit');
    setDatePickerVisible(tmpDiscount ? !!tmpDiscount.timeExpired : false);
    setTimeExpired(tmpDiscount ? tmpDiscount.timeExpired : null);
  }, [defaultDiscount, fetchId, id]);

  return discount ? (
    <div style={{ display: 'flex' }}>
      <Paper style={{ width: 'fit-content', minWidth: 500 }}>
        <Toolbar
          style={{ padding: 10, backgroundColor: '#3f51b5', color: 'white' }}
        >
          <Typography color="inherit" variant="h5" component="div">
            {t('discount:discount')}
          </Typography>
          <div
            style={{
              flex: '1 1 100%',
              marginLeft: 10,
              width: 'fit-content',
              height: 'fit-content',
            }}
          >
            <Flag flag={language}></Flag>
          </div>
          {mode === 'edit' ? (
            <Tooltip
              title={<React.Fragment>{t('global:delete')}</React.Fragment>}
            >
              <IconButton
                style={{ color: 'white' }}
                aria-label="delete"
                onClick={async () => {
                  await remove(id);
                  history.goBack();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Toolbar>
        <Box display={'flex'} padding={2} flexDirection={'column'}>
          <TextField
            className={discount.code ? undefined : classes.required}
            InputLabelProps={{
              style: { color: discount.code ? undefined : 'red' },
            }}
            label={t('discount:code')}
            variant="outlined"
            margin="dense"
            required
            value={discount.code || ''}
            onChange={(event) =>
              setDiscount({
                ...discount,
                code: event.target.value.toUpperCase(),
              })
            }
          />
          <TextField
            label={t('discount:description')}
            variant="outlined"
            margin="dense"
            value={discount.description ? discount.description[language] : ''}
            onChange={(event) => {
              const description = discount.description;
              if (!description) {
                return;
              }
              description[language] = event.target.value;
              setDiscount({ ...discount, description });
            }}
          />
          <TextField
            label={t('discount:totalUses')}
            type="number"
            variant="outlined"
            margin="dense"
            value={discount.totalUses || ''}
            onChange={(event) =>
              setDiscount({
                ...discount,
                totalUses: Number(event.target.value),
              })
            }
          />
          <TextField
            label={t('discount:flatDiscount')}
            type="number"
            variant="outlined"
            margin="dense"
            value={discount.flatDiscount || ''}
            onChange={(event) => {
              const value = Math.max(0, Number(event.target.value));
              setDiscount({
                ...discount,
                percentageDiscount: 0,
                flatDiscount: value,
              });
            }}
          />
          <TextField
            label={t('discount:percentageDiscount')}
            type="number"
            variant="outlined"
            margin="dense"
            value={discount.percentageDiscount || ''}
            onChange={(event) => {
              const value = clamp(Number(event.target.value), 0, 100);
              setDiscount({
                ...discount,
                flatDiscount: 0,
                percentageDiscount: value,
              });
            }}
          />
          <TextField
            label={t('discount:minimumAmountRequired')}
            type="number"
            variant="outlined"
            margin="dense"
            value={discount.minimumAmountRequired || ''}
            onChange={(event) =>
              setDiscount({
                ...discount,
                minimumAmountRequired: Number(event.target.value),
              })
            }
          />
          <TextField
            label={t('discount:maximumApplyableDiscount')}
            type="number"
            variant="outlined"
            margin="dense"
            value={discount.maximumApplyableDiscount || ''}
            onChange={(event) =>
              setDiscount({
                ...discount,
                maximumApplyableDiscount: Number(event.target.value),
              })
            }
          />
          <FormControlLabel
            labelPlacement="end"
            label={t('discount:hasTimeExpired')}
            control={
              <Switch
                checked={datePickerVisible}
                onChange={() => {
                  setDatePickerVisible(!datePickerVisible);
                  if (timeExpired) {
                    setTimeExpired(null);
                  } else {
                    const tomorrow = new Date();
                    tomorrow.setDate(new Date().getDate() + 1);
                    setTimeExpired(tomorrow);
                  }
                }}
              />
            }
          />
          {datePickerVisible ? (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                variant="dialog"
                disablePast={true}
                inputVariant="outlined"
                label={t('discount:timeExpired')}
                margin="dense"
                format="yyyy-MM-dd HH:mm"
                value={timeExpired}
                onChange={(date) => {
                  setTimeExpired(date ? date : discount.timeExpired);
                }}
              />
            </MuiPickersUtilsProvider>
          ) : null}
          <ProductAutocomplete
            id="freeProducts"
            textFieldLabel={t('discount:freeProducts')}
            value={discount.freeProducts}
            onChange={(products) => {
              setDiscount({ ...discount, freeProducts: products });
            }}
          />
          <ProductAutocomplete
            id="validProducts"
            textFieldLabel={t('discount:validProducts')}
            value={discount.validProducts}
            onChange={(products) => {
              setDiscount({ ...discount, validProducts: products });
            }}
          />
          <ProductAutocomplete
            id="requiredProducts"
            textFieldLabel={t('discount:requiredProducts')}
            value={discount.requiredProducts}
            onChange={(products) => {
              setDiscount({ ...discount, requiredProducts: products });
            }}
          />
          <Button
            style={{ marginTop: 5 }}
            variant="contained"
            color="primary"
            disabled={!discount.code}
            onClick={async () => {
              try {
                if (mode === 'edit') {
                  await update(discount.id, {
                    ...discount,
                    timeExpired: timeExpired,
                  });
                } else {
                  await create({
                    ...discount,
                    timeExpired: timeExpired,
                  });
                  history.goBack();
                }
              } catch {
                toast.error(t('global:somethingWentWrong'));
              }
            }}
          >
            {mode === 'edit' ? t('global:save') : t('global:add')}
          </Button>
        </Box>
      </Paper>
      <Paper
        style={{
          minWidth: 100,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          display: 'flex',
          backgroundColor: '#eeeeee',
        }}
      >
        <LanguageSelector
          languages={languages.map((code) => ({
            ...code,
            onSelect: (lng) => {
              setLanguage(lng);
            },
          }))}
        ></LanguageSelector>
      </Paper>
    </div>
  ) : (
    <Box display={'flex'}>
      {loading ? (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </Box>
  );
};
