export enum LanguageCode {
  en = 'en',
  rs = 'rs',
}

export const emptyMultipleLanguageString = (): Record<
  LanguageCode,
  string
> => ({
  en: '',
  rs: '',
});
