import { createTheme } from '@material-ui/core';

export const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        borderLeft: '2px solid rgba(224, 224, 224, 0.8)',
      },
    },
    MuiTypography: {
      h6: {
        fontSize: '1.1rem',
        fontWeight: 400,
      },
    },
    MuiToolbar: {
      regular: {
        borderLeft: '2px solid rgba(224, 224, 224, 0.8)',
        minHeight: 0,
        padding: '5px 5px 5px 15px',
        '@media (min-width: 600px)': {
          minHeight: 0,
        },
        '@media (min-width: 0px) and (orientation: landscape)': {
          minHeight: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 2.5,
      },
      sizeSmall: {
        padding: 2.5,
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: 0,
        padding: 0,
      },
    },
  },
});
