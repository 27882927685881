import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import {
  EmailPreference,
  emailPreferenceService,
  EmailPreferenceType,
} from '../email-preference/email-preference.service';

export const useEmailPreference = (userId: string) => {
  const [emailPreferences, setEmailPreferences] = useState<EmailPreference[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const fetchedPreferences =
        await emailPreferenceService.fetchPreferencesForUserId(userId);
      setEmailPreferences(fetchedPreferences);
    } catch {}
    setLoading(false);
  }, [userId]);

  const create = useCallback(
    async (payload: { userId: string; type: EmailPreferenceType }) => {
      setLoading(true);
      try {
        await emailPreferenceService.create(payload);
        toast.success('Email Preference Added', { id: 'emailPreferenceAdded' });
      } catch {}
      refresh();
    },
    [refresh],
  );

  const remove = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await emailPreferenceService.remove(id);

        toast.success('Email preference removed', { id: 'userRemoveSuccess' });
      } catch {}

      refresh();
    },
    [refresh],
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  const hookObject = useMemo(
    () => ({ emailPreferences, create, remove, loading }),
    [create, loading, remove, emailPreferences],
  );

  return hookObject;
};
