import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Product, productService } from '../product/product.service';

export const useProduct = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const fetchedProducts = await productService.fetchAll();
      setProducts(fetchedProducts);
    } catch {}
    setLoading(false);
  }, []);

  const fetchId = useCallback(
    (id: string) => {
      return products.find((product) => product.id === id);
    },
    [products],
  );

  const create = useCallback(
    async (payload: Product) => {
      setLoading(true);
      try {
        await productService.create(payload);
        toast.success('Product added', { id: 'productCreateSuccess' });
      } catch {}
      refresh();
    },
    [refresh],
  );

  const remove = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await productService.remove(id);

        toast.success('Product removed', { id: 'productRemoveSuccess' });
      } catch {}

      refresh();
    },
    [refresh],
  );

  const update = useCallback(
    async (
      id: string,
      updatedProperties: Partial<Product>,
      image?: FormData,
    ) => {
      setLoading(true);
      try {
        await productService.update(id, updatedProperties);
        toast.success('Update sucessful', {
          id: 'orderUpdateSuccess',
        });
        if (image) {
          await productService.updateImage(id, image);

          toast.success('Image update sucessful', {
            id: 'productImageUpdateSuccess',
          });
        }
      } catch {}
      refresh();
    },
    [refresh],
  );

  const swapIndex = useCallback(
    async (firstId: string, secondId: string) => {
      setLoading(true);
      try {
        await productService.swapIndex(firstId, secondId);
        toast.success('Position update sucessful', {
          id: 'productUpdateSuccess',
        });
      } catch {}
      refresh();
    },
    [refresh],
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  const hookObject = useMemo(
    () => ({
      products,
      fetchId,
      create,
      remove,
      update,
      swapIndex,
      loading,
    }),
    [create, fetchId, loading, products, remove, swapIndex, update],
  );

  return hookObject;
};
