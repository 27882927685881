import { useEffect, useState } from 'react';
import { loginService } from '../../core/login/login.service';
import { Login } from '../login/login.container';

export const AuthenticationGuard: React.FC = (props) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    loginService
      .currentSession()
      .then(() => setAuthenticated(true))
      .catch(() => {});
  }, []);

  return authenticated ? (
    <>{props.children}</>
  ) : (
    <Login setAuthentication={setAuthenticated}></Login>
  );
};
