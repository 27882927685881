import { useMemo, useState, useEffect, useCallback } from 'react';
import {
  Configuration,
  configurationService,
} from '../configuration/configuration.service';
import toast from 'react-hot-toast';

export const useConfiguration = () => {
  const [configuration, setConfiguration] = useState<Configuration>();
  const [loading, setLoading] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const fetchedConfiguration = await configurationService.fetchLatest();
      setConfiguration(fetchedConfiguration.data);
    } catch {}
    setLoading(false);
  }, []);

  const update = useCallback(
    async (updatedProperties: Configuration) => {
      setLoading(true);
      try {
        await configurationService.update(updatedProperties);
        toast.success('Update sucessful', { id: 'configurationUpdateSuccess' });
      } catch {}
      refresh();
    },
    [refresh],
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  const hookObject = useMemo(
    () => ({ configuration, update, loading }),
    [configuration, update, loading],
  );
  return hookObject;
};
