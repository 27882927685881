import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      discount: {
        discounts: 'Discounts',
        discount: 'Discount',
        code: 'Code',
        timeCreated: 'Time created',
        timeExpired: 'Expiry date',
        description: 'Description',
        totalUses: 'Total uses',
        flatDiscount: 'Flat discount',
        percentageDiscount: 'Percentage discount',
        minimumAmountRequired: 'Minimum amount required',
        maximumApplyableDiscount: 'Maximum applyable discount',
        freeProducts: 'Free products',
        validProducts: 'Valid products',
        requiredProducts: 'Required products',
        amount: 'Amount',
        hasTimeExpired: 'Add expiry date',
      },
      product: {
        product: 'Product',
        products: 'Products',
        name: 'Name',
        price: 'Price',
        description: 'Description',
        available: 'Available',
        clickOrDragToUpload: 'Click or drag file to upload product image',
        fileSizeTooLarge: 'Uploaded file exceeds allowed size (2MB)',
        fileTypeBad: 'PNG images not allowed',
      },
      order: {
        order: 'Order',
        orders: 'Orders',
        number: 'Order number',
        contactNumber: 'Contact number',
        price: 'Price',
        timeCreated: 'Time created',
        status: 'Status',
        address: 'Address',
        postalCode: 'Postal code',
        city: 'City',
        email: 'Email',
        referrer: 'Referrer',
        totalPrice: 'Total price',
        discount: 'Discount',
        payableAmount: 'Payable amount',
        productImgAlt: "Couldn't load resource",
        amount: 'Amount',
        handled: 'Handled',
        cancelled: 'Cancelled',
        created: 'Created',
        handle: 'Handle',
        cancel: 'Cancel',
        fullName: 'Name',
        companyName: 'Company',
        registrationNumber: 'Company registration number',
        taxIdentificationNumber: 'TIN',
        paymentMethod: 'Payment method',
        vat: 'VAT',
      },
      orderFilters: {
        orderNumber: 'Order number',
        status: 'Status',
        search: 'Search',
        clear: 'Clear',
      },
      emailPreference: { NewOrder: 'New order email notification' },
      promotion: {
        promotions: 'Promotions',
        promotion: 'Promotion',
        code: 'Code',
        discount: 'Discount',
        products: 'Products',
        generateUrl: 'Generate URL',
        copiedToClipboard: 'Promotion URL copied to clipboard',
      },
      user: {
        users: 'Users',
        user: 'User',
        email: 'Email',
        password: 'Password',
      },
      table: {
        page: 'Page',
      },
      route: {
        discount: 'Discount',
        product: 'Product',
        order: 'Order',
        promotion: 'Promotion',
        configuration: 'Configuration',
        user: 'Accounts',
      },
      login: {
        login: 'LOGIN',
        email: 'EMAIL',
        password: 'PASSWORD',
        appName: 'ADMIN-DASHBOARD',
      },
      languages: {
        english: 'English',
        serbian: 'Serbian',
        german: 'German',
      },
      global: {
        yes: 'Yes',
        no: 'No',
        save: 'Save',
        add: 'Add',
        delete: 'Delete',
        edit: 'Edit',
        moneyValute: 'RSD',
        moveUp: 'Move up',
        moveDown: 'Move down',
        somethingWentWrong: 'Something went wrong',
      },
      configuration: {
        configuration: 'Configuration',
        tenantName: 'Store name',
        tenantDetails: 'Store details',
        tenantAddress: 'Store address',
        tenantCity: 'Store city',
        contact: 'Contacts',
        contactFacebook: 'Facebook',
        contactInstagram: 'Instagram',
        contactGoogle: 'Google',
        contactEmail: 'Email',
        contactTelephone: 'Telephone',
        contactWhatsapp: 'Whatsapp',
        shipping: 'Shipping',
        basePrice: 'Base price',
        freeTreshold: 'Free treshold',
        minimumProducts: 'Minimum amount',
        adminUrl: 'Admin url',
        vat: 'VAT (%)',
      },
    },
  },
  keySeparator: false,
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  appendNamespaceToMissingKey: true,
});

export default i18n;
