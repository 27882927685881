import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useDiscount } from '../../core/hooks/useDiscount';
import { Discount } from '../../core/discount/discount.service';

interface Props {
  id: string;
  value?: Discount;
  onChange: (discount: Discount | null) => void;
  textFieldLabel: string;
}

export const DiscountAutocomplete: React.FC<Props> = ({
  id,
  value,
  onChange,
  textFieldLabel,
}) => {
  const { discounts } = useDiscount();

  return (
    <Autocomplete
      id={id}
      style={{ marginTop: 8, marginBottom: 4 }}
      options={discounts}
      value={value ?? null}
      getOptionLabel={(discount) => discount.code}
      getOptionSelected={(option, value) =>
        value ? option.id === value.id : false
      }
      onChange={(event, discount) => {
        onChange(discount);
      }}
      renderInput={(params) => (
        <TextField {...params} label={textFieldLabel} variant="outlined" />
      )}
    />
  );
};
