import React from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@material-ui/icons/FilterNone';
import { usePromotion } from '../../core/hooks/usePromotion';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

export const PromotionsTable: React.FC = () => {
  const { promotions, remove: promotionRemove, loading } = usePromotion();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Paper style={{ width: '60vw', overflowY: 'hidden' }}>
      <Toolbar
        style={{ padding: 10, backgroundColor: '#3f51b5', color: 'white' }}
      >
        <Typography
          style={{ flex: '1 1 100%' }}
          color="inherit"
          variant="h5"
          component="div"
        >
          {t('promotion:promotions')}
        </Typography>
        <Tooltip title={<React.Fragment>{t('global:add')}</React.Fragment>}>
          <IconButton
            style={{ color: 'white' }}
            aria-label="add"
            onClick={() => history.push(history.location.pathname + `/add`)}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <TableContainer>
        <Table>
          <TableHead>
            {loading ? null : (
              <TableRow>
                <TableCell>{t('promotion:code')}</TableCell>
                <TableCell>{t('promotion:discount')}</TableCell>
                <TableCell>{t('promotion:products')}</TableCell>
                <TableCell
                  style={{
                    borderLeftWidth: 0,
                  }}
                ></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center">
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            ) : (
              promotions.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.discount?.code}</TableCell>
                  <TableCell>
                    {row.promotionProducts && row.promotionProducts.length
                      ? row.promotionProducts
                          .map((product) => product.name.en)
                          .join(', ')
                      : ''}
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeftWidth: 0,
                      minWidth: 80,
                    }}
                    align="right"
                  >
                    <Tooltip
                      style={{ marginRight: 5 }}
                      title={
                        <React.Fragment>
                          {t('promotion:generateUrl')}
                        </React.Fragment>
                      }
                    >
                      <IconButton
                        aria-label="contentCopy"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            window.location.host.replace('admin', 'www') +
                              `/?promo=${row.code}&lang=rs`,
                          );
                          toast.success(t('promotion:copiedToClipboard'), {
                            id: 'copiedToClipboard',
                          });
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      style={{ marginRight: 5 }}
                      title={
                        <React.Fragment>{t('global:edit')}</React.Fragment>
                      }
                    >
                      <IconButton
                        aria-label="edit"
                        onClick={() =>
                          history.push(history.location.pathname + `/${row.id}`)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        <React.Fragment>{t('global:delete')}</React.Fragment>
                      }
                    >
                      <IconButton
                        aria-label="delete"
                        onClick={async (event) => {
                          promotionRemove(row.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
