import { List } from '@material-ui/core';
import { NavigationLink } from './navigation-link.component';

export interface NavigationMenuLink {
  url: string;
  external?: boolean;
}

const menuElements: NavigationMenuLink[] = [
  {
    url: 'order',
  },
  {
    url: 'discount',
  },
  {
    url: 'product',
  },
  {
    url: 'promotion',
  },
  {
    url: 'user',
  },
  {
    url: 'configuration',
  },
];

export const NavigationMenu: React.FC = () => {
  return (
    <List style={{ paddingTop: 0 }}>
      {menuElements.map((element, index) => (
        <NavigationLink link={element} key={index} />
      ))}
    </List>
  );
};
