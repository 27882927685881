import React from 'react';
import { LanguageCode } from '../../core/global/language.constant';
import rsFlag from '../../resources/rsFlag.svg';
import enFlag from '../../resources/ukFlag.svg';

const flagForCountry: Record<LanguageCode, string> = {
  [LanguageCode.en]: enFlag,
  [LanguageCode.rs]: rsFlag,
};

type Props = {
  flag: LanguageCode;
};

export const Flag: React.FC<Props> = ({ flag }) => {
  return (
    <img
      style={{ width: 24, height: 24 }}
      src={flagForCountry[flag]}
      alt="flag"
    />
  );
};
