import React from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../core/hooks/useUser';
import AddIcon from '@material-ui/icons/Add';

export const UserTable: React.FC = () => {
  const { users, remove: userRemove, loading } = useUser();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Paper style={{ width: '80vh', overflowY: 'hidden' }}>
      <Toolbar
        style={{ padding: 10, backgroundColor: '#3f51b5', color: 'white' }}
      >
        <Typography
          style={{ flex: '1 1 100%' }}
          color="inherit"
          variant="h5"
          component="div"
        >
          {t('user:users')}
        </Typography>
        <Tooltip title={<React.Fragment>{t('global:add')}</React.Fragment>}>
          <IconButton
            style={{ color: 'white' }}
            aria-label="add"
            onClick={() => history.push(history.location.pathname + `/add`)}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <TableContainer>
        <Table>
          <TableHead>
            {loading ? null : (
              <TableRow>
                <TableCell>{t('user:email')}</TableCell>
                <TableCell
                  style={{
                    borderLeftWidth: 0,
                  }}
                ></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center">
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            ) : (
              users.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.email}</TableCell>
                  <TableCell
                    style={{
                      borderLeftWidth: 0,
                      minWidth: 80,
                    }}
                    align="right"
                  >
                    <Tooltip
                      style={{ marginRight: 5 }}
                      title={
                        <React.Fragment>{t('global:edit')}</React.Fragment>
                      }
                    >
                      <IconButton
                        aria-label={t('global:edit')}
                        onClick={() =>
                          history.push(history.location.pathname + `/${row.id}`)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        <React.Fragment>{t('global:delete')}</React.Fragment>
                      }
                    >
                      <IconButton
                        aria-label={t('global:delete')}
                        onClick={async () => {
                          userRemove(row.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
