import { Discount } from '../discount/discount.service';
import { http } from '../global/http/http';
import { Product } from '../product/product.service';

export interface Promotion {
  id: string;
  code: string;
  discount?: Discount;
  promotionProducts: (Product & { quantity: number })[];
}

export interface PromotionCreatePayload {
  code: string;
  discountId?: string;
  promotionProducts: (Product & { quantity: number })[];
}
export interface PromotionUpdatePayload {
  code?: string;
  discountId?: string;
  promotionProducts: (Product & { quantity: number })[];
}

const fetchAll = async (): Promise<Promotion[]> => {
  return http.get$('/promotion');
};

const fetchId = async (id: string): Promise<Promotion> => {
  return http.get$(`/promotion/${id}`);
};

const update = async (
  id: string,
  updatedProperties: Partial<PromotionUpdatePayload>,
): Promise<void> => {
  return http.patch$('/promotion', {
    ...updatedProperties,
    promotionProducts: updatedProperties.promotionProducts?.map((x) => ({
      productId: x.id,
      quantity: x.quantity,
    })),
    id,
  });
};

const create = async (
  payload: PromotionCreatePayload,
  id?: string,
): Promise<void> => {
  http.post$('/promotion', {
    ...payload,
    promotionProducts: payload.promotionProducts.map((x) => ({
      productId: x.id,
      quantity: x.quantity,
    })),
    id,
  });
};

const remove = async (id: string): Promise<void> => {
  return http.delete$(`/promotion/${id}`);
};

export const promotionService = {
  fetchAll,
  fetchId,
  update,
  create,
  remove,
};
