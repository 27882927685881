import { http } from '../global/http/http';

export interface EmailPreference {
  id: string;
  userId: string;
  type: EmailPreferenceType;
}

export enum EmailPreferenceType {
  NewOrder = 'NewOrder',
}

const remove = async (id: string): Promise<void> => {
  return http.delete$(`/email-preference/${id}`);
};

const create = async (payload: {
  userId: string;
  type: EmailPreferenceType;
}): Promise<void> => {
  return http.post$('/email-preference', payload);
};

const fetchPreferencesForUserId = async (
  userId: string,
): Promise<EmailPreference[]> => {
  return http.get$(`/email-preference?userId=${userId}`);
};

export const emailPreferenceService = {
  remove,
  create,
  fetchPreferencesForUserId,
};
