import { http } from '../global/http/http';

export interface User {
  sessionToken: string;
  email: string;
  id: string;
  password: string;
}

const fetchAll = async (): Promise<User[]> => {
  return http.get$('/user');
};

const fetchId = async (id: string): Promise<User> => {
  return http.get$(`/user/${id}`);
};

const update = async (id: string, payload: Partial<User>): Promise<void> => {
  return http.patch$('/user', { id, ...payload });
};

const remove = async (id: string): Promise<void> => {
  return http.delete$(`/user/${id}`);
};

const create = async (payload: User): Promise<void> => {
  return http.post$('/user', payload);
};

export const userService = {
  fetchAll,
  fetchId,
  remove,
  update,
  create,
};
