import React, { useState } from 'react';
import { OrderFilter } from '../../core/hooks/useOrder';
import { TextField, Button, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export const OrderTableFilters: React.FC<{
  setFilter: React.Dispatch<React.SetStateAction<OrderFilter>>;
}> = ({ setFilter }) => {
  const [localFilters, setLocalFilters] = useState<OrderFilter>({});

  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignSelf="flex-start"
      alignItems="center"
      padding={1}
      width={'98%'}
    >
      <Box style={{ display: 'flex', flex: 2 }}>
        <TextField
          margin="dense"
          label={t('orderFilters:orderNumber')}
          value={
            localFilters.orderNumber === undefined
              ? ''
              : localFilters.orderNumber
          }
          onChange={(e) => {
            const parsedValue = Number(e.currentTarget.value);
            if (isNaN(parsedValue)) {
              return;
            }
            setLocalFilters({
              ...localFilters,
              orderNumber: parsedValue,
            });
          }}
          style={{
            flex: 1,
          }}
          variant="outlined"
        ></TextField>
      </Box>
      <Box style={{ display: 'flex', flex: 2, paddingLeft: 10 }}>
        <Autocomplete
          options={['Created', 'Handled']}
          value={localFilters.status ?? null}
          onChange={(e, status) => {
            setLocalFilters({ ...localFilters, status: status ?? undefined });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label={t('orderFilters:status')}
              variant="outlined"
            />
          )}
          style={{ flex: 1 }}
        />
      </Box>
      <Box
        alignItems={'flex-end'}
        display={'flex'}
        flex={3}
        paddingLeft={'10px'}
      >
        <p style={{ marginRight: 8 }}>From:</p>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            ampm={false}
            animateYearScrolling={true}
            type="dialog"
            autoOk={true}
            disableFuture={true}
            format="dd/MM/yy HH:mm"
            inputVariant="outlined"
            variant="inline"
            fullWidth
            margin="dense"
            value={localFilters.afterDate}
            onChange={(date: Date | null) => {
              if (date) {
                setLocalFilters({
                  ...localFilters,
                  afterDate: date,
                });
              }
            }}
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Box
        alignItems={'flex-end'}
        display={'flex'}
        flex={3}
        paddingLeft={'10px'}
      >
        <p style={{ marginRight: 8 }}>To:</p>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            ampm={false}
            animateYearScrolling={true}
            type="dialog"
            autoOk={true}
            format="dd/MM/yy HH:mm"
            inputVariant="outlined"
            variant="inline"
            fullWidth
            margin="dense"
            value={localFilters.beforeDate}
            onChange={(date: Date | null) => {
              if (date) {
                setLocalFilters({
                  ...localFilters,
                  beforeDate: date,
                });
              }
            }}
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Box
        style={{
          display: 'flex',
          paddingLeft: 10,
          marginTop: 8,
          marginBottom: 6,
          flex: 1,
        }}
      >
        <Button
          style={{ flex: 1, backgroundColor: '#3f51b5', color: 'white' }}
          variant={'contained'}
          onClick={() => setFilter({ ...localFilters })}
        >
          {t('orderFilters:search')}
        </Button>
        <Button
          style={{
            flex: 1,
            backgroundColor: '#3f51b5',
            color: 'white',
            marginLeft: 10,
          }}
          variant={'contained'}
          onClick={() => {
            setFilter({});
            setLocalFilters({});
          }}
        >
          {t('orderFilters:clear')}
        </Button>
      </Box>
    </Box>
  );
};
