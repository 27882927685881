import { Button, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  label: string;
  quantity: number;
  onChange?: (x: number) => void;
}

export const Counter: React.FC<Props> = ({ label, onChange, quantity }) => {
  return (
    <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
      <Button
        variant="outlined"
        style={{ flex: 1, margin: 10, marginLeft: 0 }}
        onClick={() => {
          if (onChange && quantity > 1) {
            onChange(quantity - 1);
          }
        }}
      >
        <Typography variant="h4">-</Typography>
      </Button>
      <Typography variant="h6" style={{ flex: 4, textAlign: 'center' }}>
        {label}
      </Typography>
      <Button
        variant="outlined"
        style={{ flex: 1, margin: 10, marginRight: 0 }}
        onClick={() => {
          if (onChange) {
            onChange(quantity + 1);
          }
        }}
      >
        <Typography variant="h4">+</Typography>
      </Button>
      <Typography variant="h6" style={{ flex: 2, textAlign: 'center' }}>
        x{quantity}
      </Typography>
    </div>
  );
};
