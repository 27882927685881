import { createStyles, Drawer, makeStyles } from '@material-ui/core';
import { Route } from 'react-router-dom';
import { DiscountElement } from '../discounts-table/discount-element.component';
import { DiscountsTable } from '../discounts-table/discounts-table.container';
import { OrderElement } from '../orders-table/order-element.component';
import { OrderTable } from '../orders-table/order-table.container';
import { UserTable } from '../user-table/user-table.container';
import { ProductElement } from '../products-table/product-element.component';
import { ProductTable } from '../products-table/product-table.container';
import { PromotionElement } from '../promotions-table/promotion-element.component';
import { PromotionsTable } from '../promotions-table/promotions-table.container';
import { NavigationMenu } from './navigation-menu.component';
import { UserElement } from '../user-table/user-element.component';
import { ConfigurationContainer } from '../configuration/configuration.container';

const drawerWidth = 200;

const useStyles = makeStyles(() =>
  createStyles({
    drawerPaper: {
      width: drawerWidth,
    },
  }),
);

export const NavigationProvider: React.FC = () => {
  const classes = useStyles();

  return (
    <div style={{ display: 'flex' }}>
      <Drawer
        style={{
          width: drawerWidth,
          flexShrink: 0,
        }}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div
          style={{
            overflow: 'auto',
          }}
        >
          <NavigationMenu />
        </div>
      </Drawer>
      <main style={{ flex: 1, padding: 10 }}>
        <Route exact path="/" component={OrderTable} />
        <Route exact path="/order" component={OrderTable} />
        <Route path="/order/:id" component={OrderElement} />
        <Route exact path="/discount" component={DiscountsTable} />
        <Route path="/discount/:id" component={DiscountElement} />
        <Route exact path="/product" component={ProductTable} />
        <Route path="/product/:id" component={ProductElement} />
        <Route exact path="/user" component={UserTable} />
        <Route path="/user/:id" component={UserElement} />
        <Route exact path="/configuration" component={ConfigurationContainer} />
        <Route exact path="/promotion" component={PromotionsTable} />
        <Route path="/promotion/:id" component={PromotionElement} />
      </main>
    </div>
  );
};
