import {
  IconButton,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
  Divider,
  Toolbar,
  Tooltip,
  Card,
  CardContent,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  Order,
  orderService,
  OrderStatus,
} from '../../core/order/order.service';
import CancelIcon from '@material-ui/icons/Cancel';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useOrder } from '../../core/hooks/useOrder';

const stockImageUrl =
  'https://media.istockphoto.com/photos/wine-bottle-picture-id182837880?k=20&m=182837880&s=170667a&w=0&h=SRogjWC0MQ-1is6KlHhYnKraqDxtCwA0TlYrCWWrEtw=';

export const OrderElement: React.FC = () => {
  const { update } = useOrder();
  const [order, setOrder] = useState<Order | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  useEffect(() => {
    orderService
      .fetchId(id)
      .then((x) => {
        setOrder(x);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id]);

  return order ? (
    <div style={{ display: 'flex' }}>
      <Paper style={{ maxWidth: '60vw', minWidth: 500 }}>
        <Toolbar
          style={{ padding: 10, backgroundColor: '#3f51b5', color: 'white' }}
        >
          <Typography
            style={{ flex: '1 1 100%' }}
            color="inherit"
            variant="h5"
            component="div"
          >
            {t('order:order') + ' #' + order.number}
          </Typography>
          {![order.status].includes(
            OrderStatus.Handled || OrderStatus.Cancelled,
          ) ? (
            <Tooltip
              title={<React.Fragment>{t('order:cancel')}</React.Fragment>}
            >
              <IconButton
                style={{ color: 'white' }}
                aria-label="delete"
                onClick={async () => {
                  await update(id, OrderStatus.Cancelled);
                  history.goBack();
                }}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Toolbar>
        <Box display={'flex'} margin={1} flexDirection={'column'}>
          {order.shippingDetails.fullName ? (
            <Box display={'flex'} alignItems={'baseline'} margin={1}>
              <Typography variant={'h5'}>{t('order:fullName')}:</Typography>
              <Typography variant={'h6'} style={{ marginLeft: 8 }}>
                {order.shippingDetails.fullName}
              </Typography>
            </Box>
          ) : null}
          <Divider />
          {order.shippingDetails.companyName ? (
            <Box display={'flex'} alignItems={'baseline'} margin={1}>
              <Typography variant={'h5'}>{t('order:companyName')}:</Typography>
              <Typography variant={'h6'} style={{ marginLeft: 8 }}>
                {order.shippingDetails.companyName}
              </Typography>
            </Box>
          ) : null}
          <Divider />
          {order.shippingDetails.registrationNumber ? (
            <Box display={'flex'} alignItems={'baseline'} margin={1}>
              <Typography variant={'h5'}>
                {t('order:registrationNumber')}:
              </Typography>
              <Typography variant={'h6'} style={{ marginLeft: 8 }}>
                {order.shippingDetails.registrationNumber}
              </Typography>
            </Box>
          ) : null}
          <Divider />
          {order.shippingDetails.taxIdentificationNumber ? (
            <Box display={'flex'} alignItems={'baseline'} margin={1}>
              <Typography variant={'h5'}>
                {t('order:taxIdentificationNumber')}:
              </Typography>
              <Typography variant={'h6'} style={{ marginLeft: 8 }}>
                {order.shippingDetails.taxIdentificationNumber}
              </Typography>
            </Box>
          ) : null}
          <Divider />
          <Box display={'flex'} alignItems={'baseline'} margin={1}>
            <Typography variant={'h5'}>{t('order:address')}:</Typography>
            <Typography variant={'h6'} style={{ marginLeft: 8 }}>
              {order.shippingDetails.address}
            </Typography>
          </Box>
          <Divider />
          <Box display={'flex'} alignItems={'baseline'} margin={1}>
            <Typography variant={'h5'}>{t('order:postalCode')}:</Typography>
            <Typography variant={'h6'} style={{ marginLeft: 8 }}>
              {order.shippingDetails.postalCode}
            </Typography>
          </Box>
          <Divider />
          <Box display={'flex'} alignItems={'baseline'} margin={1}>
            <Typography variant={'h5'}>{t('order:city')}:</Typography>
            <Typography variant={'h6'} style={{ marginLeft: 8 }}>
              {order.shippingDetails.city}
            </Typography>
          </Box>
          <Divider />
          <Box display={'flex'} alignItems={'baseline'} margin={1}>
            <Typography variant={'h5'}>{t('order:contactNumber')}:</Typography>
            <Typography variant={'h6'} style={{ marginLeft: 8 }}>
              {order.shippingDetails.contactNumber}
            </Typography>
          </Box>
          <Divider />
          <Box display={'flex'} alignItems={'baseline'} margin={1}>
            <Typography variant={'h5'}>{t('order:email')}:</Typography>
            <Typography variant={'h6'} style={{ marginLeft: 8 }}>
              {order.shippingDetails.email}
            </Typography>
          </Box>
          <Divider />
          <Box display={'flex'} alignItems={'baseline'} margin={1}>
            <Typography variant={'h5'}>{t('order:referrer')}:</Typography>
            <Typography variant={'h6'} style={{ marginLeft: 8 }}>
              {order.referrer}
            </Typography>
          </Box>
          <Divider />
          <Box display={'flex'} alignItems={'baseline'} margin={1}>
            <Typography variant={'h5'}>{t('order:totalPrice')}:</Typography>
            <Typography variant={'h6'} style={{ marginLeft: 8 }}>
              {order.totalAmount}
            </Typography>
          </Box>
          <Divider />
          <Box display={'flex'} alignItems={'baseline'} margin={1}>
            <Typography variant={'h5'}>{t('order:discount')}:</Typography>
            <Typography variant={'h6'} style={{ marginLeft: 8 }}>
              {order.discountAmount}
            </Typography>
          </Box>
          <Divider />
          <Box display={'flex'} alignItems={'baseline'} margin={1}>
            <Typography variant={'h5'}>{t('order:vat')}:</Typography>
            <Typography variant={'h6'} style={{ marginLeft: 8 }}>
              {order.vatAmount}
            </Typography>
          </Box>
          <Divider />
          <Box display={'flex'} alignItems={'baseline'} margin={1}>
            <Typography variant={'h5'}>{t('order:payableAmount')}:</Typography>
            <Typography variant={'h6'} style={{ marginLeft: 8 }}>
              {order.payableAmount}
            </Typography>
          </Box>
          <Divider />
          <Box display={'flex'} alignItems={'baseline'} margin={1}>
            <Typography variant={'h5'}>{t('order:paymentMethod')}:</Typography>
            <Typography variant={'h6'} style={{ marginLeft: 8 }}>
              {order.paymentMethod}
            </Typography>
          </Box>
          <Divider />
          <ScrollContainer
            hideScrollbars={false}
            style={{
              display: 'flex',
              height: 430,
            }}
          >
            {order.orderedProducts.length
              ? order.orderedProducts.map((orderedProduct) => (
                  <Card
                    key={orderedProduct.id}
                    style={{ width: 240, flexShrink: 0 }}
                  >
                    <img
                      alt={t('order:productImgAlt')}
                      height={240}
                      src={
                        orderedProduct.product.image
                          ? orderedProduct.product.image.url
                          : stockImageUrl
                      }
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    ></img>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {orderedProduct.product.name.en}
                      </Typography>
                      <Box display={'flex'} alignItems={'baseline'}>
                        <Typography variant={'body2'}>
                          {t('order:price')}:
                        </Typography>
                        <Typography variant={'body2'}>
                          {orderedProduct.price}
                        </Typography>
                      </Box>
                      <Box display={'flex'} alignItems={'baseline'}>
                        <Typography variant={'body2'}>
                          {t('order:amount')}:
                        </Typography>
                        <Typography variant={'body2'}>
                          {orderedProduct.quantity}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                ))
              : null}
          </ScrollContainer>
          {![order.status].includes(
            OrderStatus.Handled || OrderStatus.Cancelled,
          ) ? (
            <Button
              style={{ flex: 1, margin: 5, marginTop: 20 }}
              variant="contained"
              color="primary"
              onClick={async () => {
                await update(id, OrderStatus.Handled);
                history.goBack();
              }}
            >
              {t('order:handle')}
            </Button>
          ) : null}
        </Box>
      </Paper>
    </div>
  ) : (
    <Box display={'flex'}>
      {loading ? (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </Box>
  );
};
