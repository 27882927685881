import React, { useRef, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
  Button,
  Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import CancelIcon from '@material-ui/icons/Cancel';
import { useOrder } from '../../core/hooks/useOrder';
import { useTranslation } from 'react-i18next';
import { OrderStatus } from '../../core/order/order.service';
import { OrderTableFilters } from './order-table-filters.component';

export const OrderTable: React.FC = () => {
  const { orders, update, loading, page, setPage, setFilter, lastPage } =
    useOrder();
  const history = useHistory();
  const { t } = useTranslation();

  const tableRef = useRef<HTMLTableElement | null>(null);

  const [showFilterOptions, setShowFilterOptions] = useState<boolean>(false);

  return (
    <Paper style={{ width: '70vw', overflowY: 'hidden' }}>
      <Toolbar
        style={{
          padding: 10,
          backgroundColor: '#3f51b5',
          color: 'white',
        }}
      >
        <Typography
          style={{ flex: '1 1 100%' }}
          color="inherit"
          variant="h5"
          component="div"
        >
          {t('order:orders')}
        </Typography>
      </Toolbar>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderBottom: '1px solid rgba(224, 224, 224, 0.8)',
          borderLeft: '2px solid rgba(224, 224, 224, 0.8)',
        }}
      >
        {showFilterOptions ? <OrderTableFilters setFilter={setFilter} /> : null}
        <Box display="flex" justifyContent="center">
          <Button
            style={{ flex: 1, paddingBottom: 8 }}
            onClick={() => setShowFilterOptions(!showFilterOptions)}
          >
            {showFilterOptions ? <ArrowUpward /> : <ArrowDownward />}
          </Button>
        </Box>
      </div>
      <TableContainer>
        <Table
          ref={tableRef}
          style={{
            minHeight:
              loading && tableRef.current
                ? tableRef.current.clientHeight
                : undefined,
          }}
        >
          <TableHead>
            {loading ? null : (
              <TableRow>
                <TableCell>{t('order:number')}</TableCell>
                <TableCell>{t('order:timeCreated')}</TableCell>
                <TableCell>{t('order:contactNumber')}</TableCell>
                <TableCell>{t('order:price')}</TableCell>
                <TableCell>{t('order:status')}</TableCell>
                <TableCell
                  style={{
                    borderLeftWidth: 0,
                  }}
                ></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center">
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            ) : (
              orders.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.number}</TableCell>
                  <TableCell
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span style={{ display: 'flex', flex: 1 }}>
                      {new Date(row.timeCreated).toLocaleDateString()}
                    </span>
                    <span style={{ display: 'flex', flex: 1 }}>
                      {new Date(row.timeCreated).toLocaleTimeString()}
                    </span>
                  </TableCell>
                  <TableCell>{row.shippingDetails.contactNumber}</TableCell>
                  <TableCell>{row.totalAmount}</TableCell>
                  <TableCell>
                    {t(`order:${row.status.toLowerCase()}`)}
                  </TableCell>
                  <TableCell
                    style={{
                      borderLeftWidth: 0,
                      minWidth: 80,
                    }}
                    align="right"
                  >
                    <Tooltip
                      style={{ marginRight: 5 }}
                      title={
                        <React.Fragment>{t('order:handle')}</React.Fragment>
                      }
                    >
                      <IconButton
                        aria-label={t('order:handle')}
                        onClick={() => history.push('/order/' + row.id)}
                      >
                        <AssignmentIcon />
                      </IconButton>
                    </Tooltip>
                    {row.status !== OrderStatus.Handled &&
                    row.status !== OrderStatus.Cancelled ? (
                      <Tooltip
                        title={
                          <React.Fragment>{t('order:cancel')}</React.Fragment>
                        }
                      >
                        <IconButton
                          aria-label={t('order:cancel')}
                          onClick={async () => {
                            update(row.id, OrderStatus.Cancelled);
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 10,
            borderLeft: '2px solid rgba(224, 224, 224, 0.8)',
          }}
        >
          <IconButton
            disabled={page <= 0 || loading}
            onClick={() => {
              setPage(0);
            }}
          >
            <FirstPage />
          </IconButton>
          <IconButton
            disabled={page <= 0 || loading}
            onClick={() => {
              setPage(page - 1);
            }}
          >
            <ArrowLeft />
          </IconButton>
          <Box
            display="flex"
            marginX={1}
            alignItems="center"
            paddingBottom={'4px'}
          >
            {t('table:page') + ' ' + page}
          </Box>
          <IconButton
            disabled={page >= lastPage || loading}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            <ArrowRight />
          </IconButton>
          <IconButton
            disabled={page >= lastPage || loading}
            onClick={() => {
              setPage(lastPage);
            }}
          >
            <LastPage />
          </IconButton>
        </div>
      </TableContainer>
    </Paper>
  );
};
