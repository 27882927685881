import React, { useMemo } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  TextField,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Promotion } from '../../core/promotion/promotion.service';
import { ProductAutocomplete } from '../autocomplete/product-autocomplete.container';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { DiscountAutocomplete } from '../autocomplete/discount-autocomplete.container';
import { Counter } from '../counter/counter.component';
import { usePromotion } from '../../core/hooks/usePromotion';

const useStyles = makeStyles({
  required: {
    borderColor: '#f00',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
});

export const PromotionElement: React.FC = () => {
  const defaultPromotion: Promotion = useMemo(
    () => ({
      id: '',
      code: '',
      promotionProducts: [],
    }),
    [],
  );

  const { fetchId, update, create, remove, loading } = usePromotion();

  const [promotion, setPromotion] = useState<Promotion | undefined>();
  const [mode, setMode] = useState<'edit' | 'add'>('add');
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const classes = useStyles();

  useEffect(() => {
    if (id === 'add') {
      setPromotion(defaultPromotion);
      return;
    }
    setPromotion(fetchId(id));
    setMode('edit');
  }, [defaultPromotion, fetchId, id]);

  return promotion ? (
    <div style={{ display: 'flex' }}>
      <Paper style={{ width: 'fit-content', minWidth: 500 }}>
        <Toolbar
          style={{ padding: 10, backgroundColor: '#3f51b5', color: 'white' }}
        >
          <Typography
            style={{ flex: '1 1 100%' }}
            color="inherit"
            variant="h5"
            component="div"
          >
            {t('promotion:promotion')}
          </Typography>
          {mode === 'edit' ? (
            <Tooltip
              title={<React.Fragment>{t('global:delete')}</React.Fragment>}
            >
              <IconButton
                style={{ color: 'white' }}
                aria-label="delete"
                onClick={async () => {
                  await remove(id);
                  history.goBack();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Toolbar>
        <Box display={'flex'} padding={2} flexDirection={'column'}>
          <TextField
            className={promotion.code ? undefined : classes.required}
            InputLabelProps={{
              style: { color: promotion.code ? undefined : 'red' },
            }}
            label={t('promotion:code')}
            variant="outlined"
            margin="dense"
            required
            value={promotion.code || ''}
            onChange={(event) =>
              setPromotion({ ...promotion, code: event.target.value })
            }
          />
          <DiscountAutocomplete
            id="promotionDiscount"
            textFieldLabel={t('promotion:discount')}
            value={promotion.discount}
            onChange={(discount) => {
              setPromotion({ ...promotion, discount: discount ?? undefined });
            }}
          />
          <ProductAutocomplete
            id="promotionProducts"
            textFieldLabel={t('promotion:products')}
            value={promotion.promotionProducts}
            onChange={(products) => {
              setPromotion({
                ...promotion,
                promotionProducts: products.map((product) => {
                  const foundProduct = promotion.promotionProducts.find(
                    (promotionProduct) => promotionProduct.id === product.id,
                  );
                  return foundProduct ?? { ...product, quantity: 1 };
                }),
              });
            }}
          />
          {promotion.promotionProducts.length
            ? promotion.promotionProducts.map((product) => (
                <Counter
                  key={product.id}
                  label={product.name.en}
                  quantity={product.quantity}
                  onChange={(quantity) =>
                    setPromotion({
                      ...promotion,
                      promotionProducts: promotion.promotionProducts.map(
                        (promotionProduct) =>
                          promotionProduct.id === product.id
                            ? { ...product, quantity }
                            : promotionProduct,
                      ),
                    })
                  }
                />
              ))
            : null}
          <Button
            style={{ marginTop: 5 }}
            variant="contained"
            color="primary"
            disabled={!promotion.code}
            onClick={async () => {
              if (mode === 'edit') {
                await update(promotion.id, {
                  ...promotion,
                  discountId: promotion.discount?.id,
                });
              } else {
                await create({
                  ...promotion,
                  discountId: promotion.discount?.id,
                });
                history.goBack();
              }
            }}
          >
            {mode === 'edit' ? t('global:save') : t('global:add')}
          </Button>
        </Box>
      </Paper>
    </div>
  ) : (
    <Box display={'flex'}>
      {loading ? (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </Box>
  );
};
