import React, { useMemo } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import {
  languages,
  LanguageSelector,
} from '../language-selector/language-selector.component';
import { LanguageCode } from '../../core/global/language.constant';
import { Flag } from '../flag/flag.component';
import { User } from '../../core/user/user.service';
import { useUser } from '../../core/hooks/useUser';
import { EmailPreferenceComponent } from '../email-preference/email-preference.component';

export const UserElement: React.FC = () => {
  const defaultUser: User = useMemo(
    () => ({
      id: '',
      username: '',
      sessionToken: '',
      email: '',
      password: '',
    }),
    [],
  );

  const { fetchId, update, create, remove, loading } = useUser();

  const [user, setUser] = useState<User | undefined>();
  const [mode, setMode] = useState<'edit' | 'add'>('add');
  const [language, setLanguage] = useState<LanguageCode>(LanguageCode.en);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  useEffect(() => {
    if (id === 'add') {
      setUser(defaultUser);
      return;
    }
    setUser(fetchId(id));
    setMode('edit');
  }, [defaultUser, fetchId, id]);

  return user ? (
    <div style={{ display: 'flex' }}>
      <Paper style={{ width: 'fit-content', minWidth: 500 }}>
        <Toolbar
          style={{ padding: 10, backgroundColor: '#3f51b5', color: 'white' }}
        >
          <Typography color="inherit" variant="h5" component="div">
            {t('user:user')}
          </Typography>
          <div
            style={{
              flex: '1 1 100%',
              marginLeft: 10,
              width: 'fit-content',
              height: 'fit-content',
            }}
          >
            <Flag flag={language}></Flag>
          </div>
          {mode === 'edit' ? (
            <Tooltip
              title={<React.Fragment>{t('global:delete')}</React.Fragment>}
            >
              <IconButton
                style={{ color: 'white' }}
                aria-label="delete"
                onClick={async () => {
                  await remove(id);
                  history.goBack();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Toolbar>
        <Box display={'flex'} padding={2} flexDirection={'column'}>
          <TextField
            label={t('user:email')}
            type="email"
            variant="outlined"
            margin="dense"
            value={user.email}
            onChange={(event) =>
              setUser({
                ...user,
                email: event.target.value,
              })
            }
          />
          <TextField
            label={t('user:password')}
            variant="outlined"
            margin="dense"
            value={user.password}
            onChange={(event) =>
              setUser({
                ...user,
                password: event.target.value,
              })
            }
          />

          <Button
            style={{ marginTop: 5 }}
            variant="contained"
            color="primary"
            disabled={!user.email || !user.password}
            onClick={async () => {
              if (mode === 'edit') {
                await update(user.id, user);
              } else {
                await create(user);
                history.goBack();
              }
            }}
          >
            {mode === 'edit' ? t('global:save') : t('global:add')}
          </Button>
        </Box>
        {mode === 'edit' ? <EmailPreferenceComponent userId={id} /> : null}
      </Paper>
      <Paper
        style={{
          minWidth: 100,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          display: 'flex',
          backgroundColor: '#eeeeee',
        }}
      >
        <LanguageSelector
          languages={languages.map((code) => ({
            ...code,
            onSelect: (lng) => {
              setLanguage(lng);
            },
          }))}
        ></LanguageSelector>
      </Paper>
    </div>
  ) : (
    <Box display={'flex'}>
      {loading ? (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </Box>
  );
};
