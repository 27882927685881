import { Tooltip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageCode } from '../../core/global/language.constant';
import { Flag } from '../flag/flag.component';

export interface LanguageFlag {
  code: LanguageCode;
  onSelect: (code: LanguageCode) => void;
  tooltip: string;
}

export const languages: Omit<LanguageFlag, 'onSelect'>[] = [
  { code: LanguageCode.en, tooltip: 'english' },
  { code: LanguageCode.rs, tooltip: 'serbian' },
];
interface Props {
  languages: LanguageFlag[];
  direction?: 'row' | 'column';
}

export const LanguageSelector: React.FC<Props> = ({ languages, direction }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: direction,
        padding: 10,
      }}
    >
      {languages.length
        ? languages.map((language) => (
            <div
              key={language.code}
              style={{
                marginRight: 5,
                marginLeft: 5,
                cursor: 'pointer',
                height: 'fit-content',
              }}
              onClick={() => language.onSelect(language.code)}
            >
              <Tooltip
                title={
                  <React.Fragment>
                    {t(`languages:${language.tooltip}`)}
                  </React.Fragment>
                }
              >
                <div>
                  <Flag flag={language.code} />
                </div>
              </Tooltip>
            </div>
          ))
        : null}
    </div>
  );
};
