import React, { useMemo } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Product } from '../../core/product/product.service';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import {
  LanguageSelector,
  languages,
} from '../language-selector/language-selector.component';
import {
  emptyMultipleLanguageString,
  LanguageCode,
} from '../../core/global/language.constant';
import toast from 'react-hot-toast';
import { Flag } from '../flag/flag.component';
import { useProduct } from '../../core/hooks/useProduct';

const useStyles = makeStyles({
  required: {
    borderColor: '#f00',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
});

const FILE_MEGABYTE_SIZE_LIMIT = 2;

export const ProductElement: React.FC = () => {
  const defaultProduct: Product = useMemo(
    () => ({
      id: '',
      name: emptyMultipleLanguageString(),
      description: emptyMultipleLanguageString(),
      price: 0,
      available: true,
      index: 0,
    }),
    [],
  );

  const { fetchId, update, create, remove, loading } = useProduct();

  const [product, setProduct] = useState<Product | undefined>();
  const [image, setImage] = useState<FormData | undefined>();
  const [tmpImageURL, setTmpImageURL] = useState<string | undefined>();
  const [mouseOverProductImage, setMouseOverProductImage] =
    useState<boolean>(false);
  const [mode, setMode] = useState<'edit' | 'add'>('add');
  const [language, setLanguage] = useState<LanguageCode>(LanguageCode.en);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const handleFileInput = async (files: FileList) => {
    const formData = new FormData();
    if (!files.length) {
      setImage(undefined);
      return;
    }
    if (files[0].size >= FILE_MEGABYTE_SIZE_LIMIT * 1024 * 1024) {
      toast.error(t('product:fileSizeTooLarge'));
      return;
    }
    if (files[0].type === 'image/png') {
      toast.error(t('product:fileTypeBad'));
      return;
    }
    setTmpImageURL(URL.createObjectURL(files[0]));
    formData.append('content', files[0]);
    setImage(formData);
  };

  const classes = useStyles();

  useEffect(() => {
    if (id === 'add') {
      setProduct(defaultProduct);
      return;
    }
    setProduct(fetchId(id));
    setMode('edit');
  }, [defaultProduct, fetchId, id]);

  return product ? (
    <div style={{ display: 'flex' }}>
      <Paper style={{ width: 'fit-content', minWidth: 500 }}>
        <Toolbar
          style={{
            padding: 10,
            backgroundColor: '#3f51b5',
            color: 'white',
            borderLeft: '',
          }}
        >
          <Typography color="inherit" variant="h5" component="div">
            {t('product:product')}
          </Typography>
          <div
            style={{
              flex: '1 1 100%',
              marginLeft: 10,
              width: 'fit-content',
              height: 'fit-content',
            }}
          >
            <Flag flag={language} />
          </div>
          {mode === 'edit' ? (
            <Tooltip
              title={<React.Fragment>{t('global:delete')}</React.Fragment>}
            >
              <IconButton
                style={{ color: 'white' }}
                aria-label="delete"
                onClick={async () => {
                  await remove(id);
                  history.goBack();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Toolbar>
        <Box display={'flex'} padding={2} flexDirection={'column'}>
          <TextField
            className={product.name ? undefined : classes.required}
            InputLabelProps={{
              style: { color: product.name ? undefined : 'red' },
            }}
            label={t('product:name')}
            variant="outlined"
            margin="dense"
            required
            value={product.name[language] || ''}
            onChange={(event) => {
              const name = product.name;
              name[language] = event.target.value;
              setProduct({ ...product, name: name });
            }}
          />
          <TextField
            label={t('product:description')}
            variant="outlined"
            margin="dense"
            multiline={true}
            value={product.description[language] || ''}
            onChange={(event) => {
              const description = product.description;
              description[language] = event.target.value;
              setProduct({ ...product, description });
            }}
          />
          <TextField
            label={t('product:price')}
            type="number"
            variant="outlined"
            margin="dense"
            value={product.price || ''}
            onChange={(event) =>
              setProduct({ ...product, price: Number(event.target.value) })
            }
          />
          <FormControlLabel
            label={t('product:available')}
            control={
              <Switch
                checked={product.available}
                onChange={(e) =>
                  setProduct({ ...product, available: e.target.checked })
                }
              />
            }
          />
          <Paper
            elevation={6}
            style={{
              backgroundImage:
                product.image && !image
                  ? 'url(' + product.image.url + ')'
                  : image && tmpImageURL
                  ? 'url(' + tmpImageURL + ')'
                  : undefined,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: 256,
              height: 256,
              marginTop: 5,
              marginBottom: 5,
              display: 'flex',
            }}
            onMouseEnter={() => setMouseOverProductImage(true)}
            onMouseLeave={() => setMouseOverProductImage(false)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              const files = e.dataTransfer.files;
              handleFileInput(files);
            }}
          >
            {(!product.image && !tmpImageURL) || mouseOverProductImage ? (
              <IconButton
                style={{
                  display: 'flex',
                  flex: 1,
                  padding: 0,
                  borderRadius: 4,
                  backgroundColor: '#eeeb',
                }}
                component="label"
                disableRipple={true}
              >
                <label
                  style={{
                    pointerEvents: 'none',
                    marginLeft: 10,
                    marginRight: 10,
                    color: '#000',
                  }}
                >
                  {t('product:clickOrDragToUpload')}
                </label>

                <input
                  onChange={(e) => {
                    if (e.target.files) {
                      handleFileInput(e.target.files);
                    }
                  }}
                  style={{
                    textAlignLast: 'center',
                    display: 'none',
                  }}
                  type={'file'}
                  accept={'image/*'}
                />
              </IconButton>
            ) : null}
          </Paper>
          <Button
            style={{ marginTop: 10 }}
            variant="contained"
            color="primary"
            disabled={!product.name}
            onClick={async () => {
              if (mode === 'edit') {
                update(product.id, product, image);
              } else {
                await create(product);
                history.goBack();
              }
            }}
          >
            {mode === 'edit' ? t('global:save') : t('global:add')}
          </Button>
        </Box>
      </Paper>
      <Paper
        style={{
          minWidth: 100,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          display: 'flex',
          backgroundColor: '#eeeeee',
        }}
      >
        <LanguageSelector
          languages={languages.map((code) => ({
            ...code,
            onSelect: (lng) => {
              setLanguage(lng);
            },
          }))}
        ></LanguageSelector>
      </Paper>
    </div>
  ) : (
    <Box display={'flex'}>
      {loading ? (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </Box>
  );
};
