import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Order, orderService, OrderStatus } from '../order/order.service';

const maxOrdersPerPage = 8;

export interface OrderFilter {
  orderNumber?: number;
  status?: string;
  afterDate?: Date;
  beforeDate?: Date;
}

export const useOrder = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<OrderFilter>({});
  const [lastPage, setLastPage] = useState<number>(0);

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const fetchResult = await orderService.fetchAll({
        offset: page * maxOrdersPerPage,
        limit: maxOrdersPerPage,
        orderNumber: filter.orderNumber,
        status: filter.status,
        afterDate: filter.afterDate,
        beforeDate: filter.beforeDate,
      });
      setOrders(fetchResult.data);
      const newLastPage = Math.ceil(fetchResult.count / maxOrdersPerPage) - 1;
      setLastPage(newLastPage);
      if (newLastPage < page) {
        setPage(newLastPage);
      }
    } catch {}
    setLoading(false);
  }, [
    filter.afterDate,
    filter.beforeDate,
    filter.orderNumber,
    filter.status,
    page,
  ]);

  const remove = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await orderService.remove(id);
        toast.success('Order removed', { id: 'orderRemoveSuccess' });
      } catch {}
      refresh();
    },
    [refresh],
  );

  const update = useCallback(
    async (id: string, status: OrderStatus) => {
      setLoading(true);
      try {
        await orderService.update(id, status);
        toast.success('Update sucessful', {
          id: 'orderUpdateSuccess',
        });
      } catch {}
      refresh();
    },
    [refresh],
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  const hookObject = useMemo(
    () => ({
      orders,
      remove,
      update,
      loading,
      page,
      setPage,
      setFilter,
      lastPage,
    }),
    [loading, orders, remove, update, page, setPage, setFilter, lastPage],
  );

  return hookObject;
};
