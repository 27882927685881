import { http } from '../global/http/http';

interface User {
  email: string;
  password: string;
  sessionToken: string;
}

const login = async (email: string, password: string): Promise<User> => {
  return http.post$(`/user/login`, { email, password });
};

const currentSession = async (): Promise<User> => {
  return http.get$(`/user/current-session`);
};

export const loginService = {
  login,
  currentSession,
};
