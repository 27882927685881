import { environment } from '../../environment/environment';
import { HttpError, HttpMethod } from './http.constant';

const httpGeneric = async <T>(
  method: HttpMethod,
  url: string,
  data: Record<string, any> | FormData = {},
): Promise<T> => {
  const formDataType = data instanceof FormData;
  const options: RequestInit = {
    method,
    mode: 'cors',
    credentials: 'include',
    headers: {
      ...(formDataType ? {} : { 'Content-Type': 'application/json' }),
    },
    referrer: 'no-referrer',
  };

  if (
    method === HttpMethod.POST ||
    method === HttpMethod.PUT ||
    method === HttpMethod.PATCH
  ) {
    options.body = formDataType ? (data as FormData) : JSON.stringify(data);
  }

  if (method === HttpMethod.DELETE || method === HttpMethod.GET) {
    const queryParams = new URLSearchParams();
    let hasQueryParams = false;
    for (const [key, value] of Object.entries(data)) {
      if (value !== undefined) {
        hasQueryParams = true;
        const appendValue =
          value instanceof Date ? value.toISOString() : String(value);
        queryParams.append(key, appendValue);
      }
    }
    if (hasQueryParams) {
      url = `${url}?${queryParams.toString()}`;
    }
  }

  let response: Response;

  try {
    response = await fetch(`${environment.apiUrl}${url}`, options);
  } catch (error) {
    throw new HttpError({
      statusCode: 500,
      message: 'ERROR_FETCH_FAILED',
      url,
      method,
    });
  }

  if (!response.ok) {
    const body = await response.json();
    throw new HttpError({
      statusCode: response.status,
      message: body.message,
      info: body.info,
      url,
      method,
    });
  }

  try {
    const _data = (await response.json()).data;
    return _data;
  } catch (error) {
    return {} as any;
  }
};

function get$<T>(url: string, data?: Record<string, any>): Promise<T> {
  return httpGeneric<T>(HttpMethod.GET, url, data);
}

function post$<T>(
  url: string,
  data?: Record<string, any> | FormData,
): Promise<T> {
  return httpGeneric<T>(HttpMethod.POST, url, data);
}

function put$<T>(url: string, data?: Record<string, any>): Promise<T> {
  return httpGeneric<T>(HttpMethod.PUT, url, data);
}

function patch$<T>(url: string, data?: Record<string, any>): Promise<T> {
  return httpGeneric<T>(HttpMethod.PATCH, url, data);
}

function delete$<T>(url: string, data?: Record<string, any>): Promise<T> {
  return httpGeneric<T>(HttpMethod.DELETE, url, data);
}

export const http = {
  get$,
  post$,
  put$,
  patch$,
  delete$,
};
