import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Discount, discountService } from '../discount/discount.service';

export const useDiscount = () => {
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const fetchedDiscounts = await discountService.fetchAll();
      setDiscounts(fetchedDiscounts);
    } catch {}
    setLoading(false);
  }, []);

  const fetchId = useCallback(
    (id: string) => {
      return discounts.find((discount) => discount.id === id);
    },
    [discounts],
  );

  const create = useCallback(
    async (payload: Discount) => {
      setLoading(true);
      try {
        await discountService.create(payload);
        toast.success('Discount added', { id: 'discountCreateSuccess' });
      } catch {}
      refresh();
    },
    [refresh],
  );

  const remove = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await discountService.remove(id);

        toast.success('Discount removed', { id: 'discountRemoveSuccess' });
      } catch {}

      refresh();
    },
    [refresh],
  );

  const update = useCallback(
    async (id: string, updatedProperties: Partial<Discount>) => {
      setLoading(true);
      try {
        await discountService.update(id, updatedProperties);
        toast.success('Update sucessful', {
          id: 'discountUpdateSuccess',
        });
      } catch {}
      refresh();
    },
    [refresh],
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  const hookObject = useMemo(
    () => ({ discounts, fetchId, create, remove, update, loading }),
    [create, discounts, fetchId, loading, remove, update],
  );

  return hookObject;
};
