import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Promotion,
  PromotionCreatePayload,
  promotionService,
  PromotionUpdatePayload,
} from '../promotion/promotion.service';

export const usePromotion = () => {
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const fetchedPromotions = await promotionService.fetchAll();
      setPromotions(fetchedPromotions);
    } catch {}
    setLoading(false);
  }, []);

  const fetchId = useCallback(
    (id: string) => {
      return promotions.find((promotion) => promotion.id === id);
    },
    [promotions],
  );

  const create = useCallback(
    async (payload: PromotionCreatePayload) => {
      setLoading(true);
      try {
        await promotionService.create(payload);
        toast.success('Promotion added', { id: 'promotionCreateSuccess' });
      } catch {}
      refresh();
    },
    [refresh],
  );

  const remove = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await promotionService.remove(id);

        toast.success('Promotion removed', { id: 'promotionRemoveSuccess' });
      } catch {}

      refresh();
    },
    [refresh],
  );

  const update = useCallback(
    async (id: string, updatedProperties: PromotionUpdatePayload) => {
      setLoading(true);
      try {
        await promotionService.update(id, updatedProperties);
        toast.success('Update sucessful', {
          id: 'promotionUpdateSuccess',
        });
      } catch {}
      refresh();
    },
    [refresh],
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  const hookObject = useMemo(
    () => ({ promotions, fetchId, create, remove, update, loading }),
    [create, fetchId, loading, remove, update, promotions],
  );

  return hookObject;
};
