import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useProduct } from '../../core/hooks/useProduct';
import { Product } from '../../core/product/product.service';

interface Props {
  id: string;
  value?: Product[];
  onChange: (products: Product[]) => void;
  textFieldLabel: string;
}

export const ProductAutocomplete: React.FC<Props> = ({
  id,
  value,
  onChange,
  textFieldLabel,
}) => {
  const { products } = useProduct();

  return (
    <Autocomplete
      id={id}
      multiple
      style={{ marginTop: 8, marginBottom: 4 }}
      options={products}
      value={value}
      getOptionLabel={(product) => product.name.en}
      getOptionSelected={(p1, p2) => p1.id === p2.id}
      onChange={(event, products) => {
        onChange(products);
      }}
      renderInput={(params) => (
        <TextField {...params} label={textFieldLabel} variant="outlined" />
      )}
    />
  );
};
