import { http } from '../global/http/http';
import { LanguageCode } from '../global/language.constant';

export interface Image {
  id: string;
  url: string;
  name: string;
  key: string;
}

export interface Product {
  id: string;
  name: Record<LanguageCode, string>;
  description: Record<LanguageCode, string>;
  image?: Image;
  price: number;
  available: boolean;
  index: number;
}

const fetchAll = async (): Promise<Product[]> => {
  return http.get$('/product');
};

const fetchId = async (id: string): Promise<Product> => {
  return http.get$(`/product/${id}`);
};

const update = async (
  id: string,
  updatedProperties: Partial<Product>,
): Promise<void> => {
  return http.patch$('/product', { id, ...updatedProperties });
};

const updateImage = async (id: string, data: FormData): Promise<void> => {
  return http.patch$(`/product/${id}/imageUpload`, data);
};

const create = async (payload: Product): Promise<void> => {
  return http.post$('/product', payload);
};

const remove = async (id: string): Promise<void> => {
  return http.delete$(`/product/${id}`);
};

const removeImage = async (id: string): Promise<void> => {
  return http.delete$(`/product/${id}`);
};

const swapIndex = async (firstId: string, secondId: string): Promise<void> => {
  return http.patch$(`/product/swap-index`, { firstId, secondId });
};

export const productService = {
  fetchAll,
  fetchId,
  update,
  updateImage,
  create,
  remove,
  removeImage,
  swapIndex,
};
