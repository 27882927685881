import { http } from '../global/http/http';
import { Discount } from '../discount/discount.service';
import { Product } from '../product/product.service';

export enum OrderStatus {
  Handled = 'Handled',
  Created = 'Created',
  Cancelled = 'Cancelled',
}

export interface OrderedProduct {
  id: string;
  quantity: number;
  price: number;
  discountAmount: number;
  totalAmount: number;
  payableAmount: number;
  product: Product;
}

export interface ShippingDetails {
  fullName?: string;
  companyName?: string;
  registrationNumber?: string;
  taxIdentificationNumber?: string;
  contactNumber: string;
  email: string;
  address: string;
  postalCode: string;
  city: string;
}

export enum PaymentMethod {
  Cash = 'Cash',
  Invoice = 'Invoice',
}

export interface Order {
  id: string;
  number: number;

  orderedProducts: OrderedProduct[];

  discount?: Discount;
  discountId?: string;

  timeCreated: Date;

  status: OrderStatus;
  totalAmount: number;
  discountAmount: number;
  payableAmount: number;
  vatAmount: number;

  paymentMethod: PaymentMethod;
  shippingDetails: ShippingDetails;

  referrer?: string;
}

export interface FetchOrdersRequest {
  offset?: number;
  limit?: number;
  orderNumber?: number;
  status?: string;
  afterDate?: Date;
  beforeDate?: Date;
}

export type FetchPaginated<T> = {
  data: T[];
  count: number;
};

const fetchAll = async (
  payload?: FetchOrdersRequest,
): Promise<FetchPaginated<Order>> => {
  return http.get$(`/order`, payload);
};

const fetchId = async (id: string): Promise<Order> => {
  return http.get$(`/order/${id}`);
};

const update = async (id: string, status: OrderStatus): Promise<void> => {
  return http.patch$('/order', { id, status });
};

const remove = async (id: string): Promise<void> => {
  return http.delete$(`/order/${id}`);
};

export const orderService = { fetchAll, fetchId, update, remove };
