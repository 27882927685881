import React from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { useProduct } from '../../core/hooks/useProduct';
import { useTranslation } from 'react-i18next';

export const ProductTable: React.FC = () => {
  const {
    products,
    remove: productRemove,
    swapIndex: productSwapIndex,
    loading,
  } = useProduct();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Paper style={{ width: '60vw', overflowY: 'hidden' }}>
      <Toolbar
        style={{ padding: 10, backgroundColor: '#3f51b5', color: 'white' }}
      >
        <Typography
          style={{ flex: '1 1 100%' }}
          color="inherit"
          variant="h5"
          component="div"
        >
          {t('product:products')}
        </Typography>
        <Tooltip title={<React.Fragment>{t('global:add')}</React.Fragment>}>
          <IconButton
            style={{ color: 'white' }}
            aria-label="add"
            onClick={() => history.push(history.location.pathname + `/add`)}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <TableContainer>
        <Table>
          <TableHead>
            {loading ? null : (
              <TableRow>
                <TableCell>{t('product:name')}</TableCell>
                <TableCell>{t('product:price')}</TableCell>
                <TableCell>{t('product:description')}</TableCell>
                <TableCell
                  style={{
                    borderLeftWidth: 0,
                  }}
                ></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center">
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            ) : (
              products.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.name.en}</TableCell>
                  <TableCell>{row.price}</TableCell>
                  <TableCell>{row.description.en}</TableCell>
                  <TableCell
                    style={{
                      borderLeftWidth: 0,
                      minWidth: 140,
                    }}
                    align="right"
                  >
                    <Tooltip
                      style={{ marginRight: 5 }}
                      title={
                        <React.Fragment>{t('global:moveUp')}</React.Fragment>
                      }
                    >
                      <span>
                        <IconButton
                          aria-label={t('global:moveUp')}
                          disabled={index === 0}
                          onClick={() => {
                            productSwapIndex(row.id, products[index - 1].id);
                          }}
                        >
                          <ArrowUpward />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip
                      style={{ marginRight: 5 }}
                      title={
                        <React.Fragment>{t('global:moveDown')}</React.Fragment>
                      }
                    >
                      <span>
                        <IconButton
                          aria-label={t('global:moveDown')}
                          disabled={index === products.length - 1}
                          onClick={() => {
                            productSwapIndex(row.id, products[index + 1].id);
                          }}
                        >
                          <ArrowDownward />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip
                      style={{ marginRight: 5 }}
                      title={
                        <React.Fragment>{t('global:edit')}</React.Fragment>
                      }
                    >
                      <IconButton
                        aria-label={t('global:edit')}
                        onClick={() =>
                          history.push(history.location.pathname + `/${row.id}`)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        <React.Fragment>{t('global:delete')}</React.Fragment>
                      }
                    >
                      <IconButton
                        aria-label={t('global:delete')}
                        onClick={async () => {
                          productRemove(row.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
