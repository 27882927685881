import { Button, TextField, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loginService } from '../../core/login/login.service';
import './login.css';

interface Props {
  setAuthentication: (loginSuccess: boolean) => void;
}

const login = async (
  email: string,
  password: string,
  loginSuccess: (login: true) => void,
) => {
  try {
    await loginService.login(email, password);
    loginSuccess(true);
  } catch {}
};

export const Login: React.FC<Props> = (props) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void }) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        login(email, password, props.setAuthentication);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [email, password, props.setAuthentication]);

  return (
    <div className="container">
      <Typography style={{ position: 'absolute', top: 25 }} variant="h2">
        {t('login:appName')}
      </Typography>
      <div className="body">
        <Typography style={{ marginBottom: 5 }}>{t('login:email')}</Typography>
        <TextField
          size="small"
          fullWidth
          style={{ backgroundColor: 'white', marginBottom: 10 }}
          autoFocus
          type="email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></TextField>
        <Typography style={{ marginBottom: 5 }}>
          {t('login:password')}
        </Typography>
        <TextField
          size="small"
          style={{ backgroundColor: 'white', marginBottom: 10 }}
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></TextField>
        <Button
          style={{
            backgroundColor: 'white',
            borderRadius: 1,
            fontSize: 'large',
            fontWeight: 400,
            marginTop: 25,
          }}
          onKeyDownCapture={(e) => console.log(e)}
          onClick={() => login(email, password, props.setAuthentication)}
        >
          {t('login:login')}
        </Button>
      </div>
    </div>
  );
};
