import React from 'react';
import { AuthenticationGuard } from './common/authentication-guard/authentication-guard';
import { Toaster } from 'react-hot-toast';
import { NavigationProvider } from './common/navigation/navigation-provider.container';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './core/theme/theme.constant';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <AuthenticationGuard>
            <BrowserRouter basename="">
              <NavigationProvider />
            </BrowserRouter>
          </AuthenticationGuard>
        </I18nextProvider>
        <Toaster position="bottom-center" />
      </ThemeProvider>
    </>
  );
}

export default App;
