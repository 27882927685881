import { http } from '../global/http/http';

export interface Configuration {
  tenantName: string;
  tenantDetails: {
    address: string;
    city: string;
  };
  contact: {
    facebook?: string;
    instagram?: string;
    google?: string;
    email?: string;
    whatsapp?: string;
    telephone?: string;
  };
  shipping: {
    basePrice: number;
    freeTreshold: number;
    minimumProducts: number;
  };
  adminUrl: string;
  vatPercent: number;
}

const fetchLatest = async (): Promise<{
  data: Configuration;
}> => {
  return http.get$('/configuration');
};

const update = async (updatedProperties: Configuration): Promise<void> => {
  return http.patch$('/configuration', updatedProperties);
};

export const configurationService = { fetchLatest, update };
