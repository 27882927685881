import { Product } from '../product/product.service';
import { LanguageCode } from '../global/language.constant';
import { http } from '../global/http/http';

export interface Discount {
  id: string;
  code: string;
  timeCreated: Date;
  description?: Record<LanguageCode, string>;
  totalUses?: number;
  remainingUses?: number;
  minimumAmountRequired?: number;
  maximumApplyableDiscount?: number;
  percentageDiscount?: number;
  flatDiscount?: number;
  freeProducts?: Product[];
  validProducts?: Product[];
  requiredProducts?: Product[];
  timeExpired?: Date | null;
}

const fetchAll = async (): Promise<Discount[]> => {
  return http.get$('/discount');
};

const fetchId = async (id: string): Promise<Discount> => {
  return http.get$(`/discount/${id}`);
};

const update = async (
  id: string,
  updatedProperties: Partial<Discount>,
): Promise<void> => {
  return http.patch$('/discount', {
    ...updatedProperties,
    freeProducts: updatedProperties.freeProducts?.map((x) => x.id),
    validProducts: updatedProperties.validProducts?.map((x) => x.id),
    requiredProducts: updatedProperties.requiredProducts?.map((x) => x.id),
    id,
  });
};

const create = async (payload: Discount, id?: string): Promise<void> => {
  return http.post$('/discount', {
    ...payload,
    freeProducts: payload.freeProducts?.map((x) => x.id),
    validProducts: payload.validProducts?.map((x) => x.id),
    requiredProducts: payload.requiredProducts?.map((x) => x.id),
    id,
  });
};

const remove = async (id: string): Promise<void> => {
  return http.delete$(`/discount/${id}`);
};

export const discountService = { fetchAll, fetchId, update, create, remove };
