import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfiguration } from '../../core/hooks/useConfiguration';
import { Configuration } from '../../core/configuration/configuration.service';

export const ConfigurationContainer: React.FC = () => {
  const { configuration, update } = useConfiguration();
  const { t } = useTranslation();

  const [configObject, setConfigObject] = useState<Configuration>({
    tenantName: configuration?.tenantName ?? '',
    tenantDetails: {
      address: configuration?.tenantDetails.address ?? '',
      city: configuration?.tenantDetails.city ?? '',
    },
    contact: {
      facebook: configuration?.contact.facebook ?? '',
      instagram: configuration?.contact.instagram ?? '',
      google: configuration?.contact.google ?? '',
      email: configuration?.contact.email ?? '',
      whatsapp: configuration?.contact.whatsapp ?? '',
      telephone: configuration?.contact.telephone ?? '',
    },
    shipping: {
      basePrice: configuration?.shipping.basePrice ?? 0,
      freeTreshold: configuration?.shipping.freeTreshold ?? 0,
      minimumProducts: configuration?.shipping.minimumProducts ?? 0,
    },
    adminUrl: configuration?.adminUrl ?? '',
    vatPercent: configuration?.vatPercent ?? 0,
  });

  useEffect(() => {
    if (configuration) {
      setConfigObject(configuration);
    }
  }, [configuration]);

  return (
    <Paper style={{ paddingBottom: 10, width: 450 }}>
      {!configuration ? (
        <CircularProgress color="inherit" />
      ) : (
        <>
          <Toolbar
            style={{
              padding: 10,
              backgroundColor: '#3f51b5',
              color: 'white',
            }}
          >
            <Typography
              style={{ flex: '1 1 100%' }}
              color="inherit"
              variant="h5"
              component="div"
            >
              {t('configuration:configuration')}
            </Typography>
          </Toolbar>
          <Box>
            <Box
              display={'flex'}
              margin={1}
              padding={2}
              flexDirection={'column'}
            >
              <Typography align="center">
                {t('configuration:tenantDetails')}
              </Typography>
              <TextField
                style={{ marginBottom: 10 }}
                variant="outlined"
                label={t('configuration:tenantName')}
                value={configObject.tenantName}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    tenantName: e.currentTarget.value,
                  })
                }
              ></TextField>
              <TextField
                style={{ marginBottom: 10 }}
                variant="outlined"
                label={t('configuration:tenantAddress')}
                value={configObject.tenantDetails.address}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    tenantDetails: {
                      ...configObject.tenantDetails,
                      address: e.currentTarget.value,
                    },
                  })
                }
              ></TextField>
              <TextField
                variant="outlined"
                label={t('configuration:tenantCity')}
                value={configObject.tenantDetails.city}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    tenantDetails: {
                      ...configObject.tenantDetails,
                      city: e.currentTarget.value,
                    },
                  })
                }
              ></TextField>
            </Box>
            <Box
              display={'flex'}
              margin={1}
              padding={2}
              flexDirection={'column'}
            >
              <Typography align="center">
                {t('configuration:contact')}
              </Typography>
              <TextField
                style={{ marginBottom: 10 }}
                variant="outlined"
                label={t('configuration:contactFacebook')}
                value={configObject.contact.facebook}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    contact: {
                      ...configObject.contact,
                      facebook: e.currentTarget.value,
                    },
                  })
                }
              ></TextField>
              <TextField
                style={{ marginBottom: 10 }}
                variant="outlined"
                label={t('configuration:contactInstagram')}
                value={configObject.contact.instagram}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    contact: {
                      ...configObject.contact,
                      instagram: e.currentTarget.value,
                    },
                  })
                }
              ></TextField>
              <TextField
                style={{ marginBottom: 10 }}
                variant="outlined"
                label={t('configuration:contactGoogle')}
                value={configObject.contact.google}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    contact: {
                      ...configObject.contact,
                      google: e.currentTarget.value,
                    },
                  })
                }
              ></TextField>
              <TextField
                style={{ marginBottom: 10 }}
                variant="outlined"
                label={t('configuration:contactEmail')}
                value={configObject.contact.email}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    contact: {
                      ...configObject.contact,
                      email: e.currentTarget.value,
                    },
                  })
                }
              ></TextField>
              <TextField
                style={{ marginBottom: 10 }}
                variant="outlined"
                label={t('configuration:contactWhatsapp')}
                value={configObject.contact.whatsapp}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    contact: {
                      ...configObject.contact,
                      whatsapp: e.currentTarget.value,
                    },
                  })
                }
              ></TextField>
              <TextField
                variant="outlined"
                label={t('configuration:contactTelephone')}
                value={configObject.contact.telephone}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    contact: {
                      ...configObject.contact,
                      telephone: e.currentTarget.value,
                    },
                  })
                }
              ></TextField>
            </Box>
            <Box
              display={'flex'}
              margin={1}
              padding={2}
              flexDirection={'column'}
            >
              <Typography align="center">
                {t('configuration:shipping')}
              </Typography>
              <TextField
                style={{ marginBottom: 10 }}
                variant="outlined"
                type="number"
                label={t('configuration:basePrice')}
                value={configObject.shipping.basePrice || ''}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    shipping: {
                      ...configObject.shipping,
                      basePrice: Number(e.currentTarget.value),
                    },
                  })
                }
              ></TextField>
              <TextField
                style={{ marginBottom: 10 }}
                variant="outlined"
                type="number"
                label={t('configuration:freeTreshold')}
                value={configObject.shipping.freeTreshold || ''}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    shipping: {
                      ...configObject.shipping,
                      freeTreshold: Number(e.currentTarget.value),
                    },
                  })
                }
              ></TextField>
              <TextField
                variant="outlined"
                type="number"
                label={t('configuration:minimumProducts')}
                value={configObject.shipping.minimumProducts || ''}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    shipping: {
                      ...configObject.shipping,
                      minimumProducts: Number(e.currentTarget.value),
                    },
                  })
                }
              ></TextField>
            </Box>
            <Box
              display={'flex'}
              margin={1}
              padding={2}
              flexDirection={'column'}
            >
              <TextField
                variant="outlined"
                type="number"
                label={t('configuration:vat')}
                value={configObject.vatPercent}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    vatPercent: Number(e.currentTarget.value),
                  })
                }
              ></TextField>
            </Box>
            <Box
              display={'flex'}
              margin={1}
              padding={2}
              flexDirection={'column'}
            >
              <TextField
                variant="outlined"
                label={t('configuration:adminUrl')}
                value={configObject.adminUrl}
                onChange={(e) =>
                  setConfigObject({
                    ...configObject,
                    adminUrl: e.currentTarget.value,
                  })
                }
              ></TextField>
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => update(configObject)}
              >
                Update
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Paper>
  );
};
