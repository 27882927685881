import { Link, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { NavigationMenuLink } from './navigation-menu.component';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import LaunchIcon from '@material-ui/icons/Launch';
import { useTranslation } from 'react-i18next';

interface Props {
  link: NavigationMenuLink;
  sublink?: boolean;
}

export const NavigationLink: React.FC<Props> = ({ link, sublink }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();

  const isRouteSelected = `/${link.url}` === location.pathname;

  return (
    <Link
      onClick={(event: React.MouseEvent) => {
        event.preventDefault();
        link.external
          ? window.open(`/${link.url}`, '_blank', 'noopener,noreferrer')
          : history.push(`/${link.url}`);
      }}
      color="textPrimary"
      underline="none"
      href={`/${link.url}`}
      key={link.url}
    >
      <ListItem
        button
        selected={isRouteSelected}
        style={{
          borderBottomWidth: 1,
          borderColor: '#dddddd',
          borderStyle: 'solid',
        }}
      >
        {sublink ? (
          <ListItemIcon>
            <SubdirectoryArrowRightIcon
              style={{ opacity: 0.25 }}
              fontSize="small"
            />
          </ListItemIcon>
        ) : null}
        <ListItemText
          primary={t(`route:${link.url === '' ? 'order' : link.url}`)}
        />
        {link.external ? <LaunchIcon fontSize={'small'} /> : null}
      </ListItem>
    </Link>
  );
};
