import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { User, userService } from '../user/user.service';

export const useUser = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const fetchedUsers = await userService.fetchAll();
      setUsers(fetchedUsers);
    } catch {}
    setLoading(false);
  }, []);

  const fetchId = useCallback(
    (id: string) => {
      return users.find((user) => user.id === id);
    },
    [users],
  );

  const create = useCallback(
    async (payload: User) => {
      setLoading(true);
      try {
        await userService.create(payload);
        toast.success('User added', { id: 'userCreateSuccess' });
      } catch {}
      refresh();
    },
    [refresh],
  );

  const remove = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await userService.remove(id);

        toast.success('User removed', { id: 'userRemoveSuccess' });
      } catch {}

      refresh();
    },
    [refresh],
  );

  const update = useCallback(
    async (id: string, updatedProperties: Partial<User>) => {
      setLoading(true);
      try {
        await userService.update(id, updatedProperties);
        toast.success('Update sucessful', {
          id: 'userUpdateSuccess',
        });
      } catch {}
      refresh();
    },
    [refresh],
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  const hookObject = useMemo(
    () => ({ users, fetchId, create, remove, update, loading }),
    [create, fetchId, loading, remove, update, users],
  );

  return hookObject;
};
