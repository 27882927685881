import { FormControlLabel, Switch } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailPreferenceType } from '../../core/email-preference/email-preference.service';
import { useEmailPreference } from '../../core/hooks/useEmailPreference';

interface Props {
  userId: string;
}

export const EmailPreferenceComponent: React.FC<Props> = ({ userId }) => {
  const { emailPreferences, create, remove } = useEmailPreference(userId);
  const allPreferences = useMemo(
    () =>
      Object.values(EmailPreferenceType).map((preference) => ({
        type: preference,
        id:
          emailPreferences.find(
            (emailPreference) => emailPreference.type === preference,
          )?.id ?? '',
      })),
    [emailPreferences],
  );
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
      {allPreferences.map((preference) => (
        <FormControlLabel
          labelPlacement="end"
          label={t(`emailPreference:${preference.type}`)}
          control={
            <Switch
              checked={!!preference.id}
              onChange={(event) => {
                if (!event.target.checked && preference.id) {
                  remove(preference.id);
                  return;
                }
                if (event.target.checked) {
                  create({ userId, type: preference.type });
                }
              }}
            />
          }
        />
      ))}
    </div>
  );
};
